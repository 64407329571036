import React, { useEffect, useState } from "react";
import { ReactComponent as MoonIcon } from "../../../public/moon.svg";
import { ReactComponent as SunIcon } from "../../../public/sun.svg";
import styles from "./index.less";

const ThemeSwitch: React.FC = () => {
  const [themeMode, setThemeMode] = useState<string | null>();

  function invertTheme(isToggle: boolean) {
    const theme = localStorage.getItem("theme");
    const body = document.querySelector("body");
    const invert = () => body.classList.toggle("invert");

    if (isToggle) {
      if (theme === "dark" && body.classList.contains("invert")) {
        invert();
        localStorage.setItem("theme", "light");
      }
      if (theme === "light" && !body.classList.contains("invert")) {
        invert();
        localStorage.setItem("theme", "dark");
      }
    } else {
      if (theme === "dark") {
        invert();
        localStorage.setItem("theme", "dark");
      }
    }
    setThemeMode(localStorage.getItem("theme"));
  }

  useEffect(() => {
    if (!localStorage.getItem("theme")) localStorage.setItem("theme", "light");
    invertTheme(false);
  }, []);

  return themeMode === "dark" ? (
    <SunIcon onClick={() => invertTheme(true)} className={styles.buttonTheme} />
  ) : (
    <MoonIcon
      onClick={() => invertTheme(true)}
      className={styles.buttonTheme}
    />
  );
};

export default ThemeSwitch;
