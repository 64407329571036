// @ts-ignore
/* eslint-disable */
import { request } from "umi";
import axios from "axios";

/** 获取当前的用户 GET /api/currentUser */
// export async function currentUser(options?: { [key: string]: any }) {
//   return request<{
//     data: API.CurrentUser;
//   }>("/api/currentUser", {
//     method: "GET",
//     ...(options || {}),
//   });
// }

/** 退出登录接口 POST /api/login/outLogin */
export async function outLogin(options?: { [key: string]: any }) {
  return request<Record<string, any>>("/api/login/outLogin", {
    method: "POST",
    ...(options || {}),
  });
}

/** 登录接口 POST /api/login/account */
// export async function login(body: API.LoginParams, options?: { [key: string]: any }) {
//   return request<API.LoginResult>('/api/login/account', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     data: body,
//     ...(options || {}),
//   });
// }

/** 此处后端没有提供注释 GET /api/notices */
export async function getNotices(options?: { [key: string]: any }) {
  return request<API.NoticeIconList>("/api/notices", {
    method: "GET",
    ...(options || {}),
  });
}

/** 获取规则列表 GET /api/rule */
export async function rule(
  params: {
    // query
    /** 当前的页码 */
    current?: number;
    /** 页面的容量 */
    pageSize?: number;
  },
  options?: { [key: string]: any }
) {
  return request<API.RuleList>("/api/rule", {
    method: "GET",
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 新建规则 PUT /api/rule */
export async function updateRule(options?: { [key: string]: any }) {
  return request<API.RuleListItem>("/api/rule", {
    method: "PUT",
    ...(options || {}),
  });
}

/** 新建规则 POST /api/rule */
export async function addRule(options?: { [key: string]: any }) {
  return request<API.RuleListItem>("/api/rule", {
    method: "POST",
    ...(options || {}),
  });
}

/** 删除规则 DELETE /api/rule */
export async function removeRule(options?: { [key: string]: any }) {
  return request<Record<string, any>>("/api/rule", {
    method: "DELETE",
    ...(options || {}),
  });
}

export async function login(data: any) {
  return axios
    .post("/api/v1/security/login", data)
    .then(async (res: any) => {
      await axios.post(
        "/api/session/",
        {
          username: data?.username,
          password: data?.password,
        },
        { withCredentials: true }
      );
      return {
        ...res?.data,
        status: "ok",
        type: "account",
        currentAuthority: "admin",
      };
    })
    .catch(() => ({
      status: "error",
      type: "account",
      currentAuthority: "admin",
    }));
}

export async function currentUser() {
  return axios.get("/api/v1/me/roles/").then((res: any) => {
    localStorage.setItem("roles", JSON.stringify(res?.data?.result?.roles));
    return {
      data: {
        // name: `${res?.data?.result?.firstName} ${res?.data?.result?.lastName}`,
        name: res?.data?.result?.email,
        ...res?.data?.result,
        avatar: "/avatar.png",
        // avatar: "https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png",
        // userid: '00000001',
        // email: 'antdesign@alipay.com',
        // signature: '海纳百川，有容乃大',
        // title: '交互专家',
        // group: '蚂蚁金服－某某某事业群－某某平台部－某某技术部－UED',
        // tags: [
        //   {
        //     key: '0',
        //     label: '很有想法的',
        //   },
        //   {
        //     key: '1',
        //     label: '专注设计',
        //   },
        //   {
        //     key: '2',
        //     label: '辣~',
        //   },
        //   {
        //     key: '3',
        //     label: '大长腿',
        //   },
        //   {
        //     key: '4',
        //     label: '川妹子',
        //   },
        //   {
        //     key: '5',
        //     label: '海纳百川',
        //   },
        // ],
        // notifyCount: 12,
        // unreadCount: 11,
        // country: 'China',
        // access: getAccess(),
        // geographic: {
        //   province: {
        //     label: '浙江省',
        //     key: '330000',
        //   },
        //   city: {
        //     label: '杭州市',
        //     key: '330100',
        //   },
        // },
        // address: '西湖区工专路 77 号',
        // phone: '0752-268888888',
      },
    };
  });
}
