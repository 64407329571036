// @ts-nocheck

import SmileOutlined from '@ant-design/icons/SmileOutlined';
import FundOutlined from '@ant-design/icons/FundOutlined';
import AreaChartOutlined from '@ant-design/icons/AreaChartOutlined';
import SolutionOutlined from '@ant-design/icons/SolutionOutlined';
import PieChartOutlined from '@ant-design/icons/PieChartOutlined';
import FundProjectionScreenOutlined from '@ant-design/icons/FundProjectionScreenOutlined';
import DotChartOutlined from '@ant-design/icons/DotChartOutlined';
import PictureOutlined from '@ant-design/icons/PictureOutlined';
import BarsOutlined from '@ant-design/icons/BarsOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined'

export default {
  SmileOutlined,
FundOutlined,
AreaChartOutlined,
SolutionOutlined,
PieChartOutlined,
FundProjectionScreenOutlined,
DotChartOutlined,
PictureOutlined,
BarsOutlined,
SettingOutlined
}
    