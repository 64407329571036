// @ts-nocheck

  import SmileOutlined from '@ant-design/icons/es/icons/SmileOutlined';
import FundOutlined from '@ant-design/icons/es/icons/FundOutlined';
import AreaChartOutlined from '@ant-design/icons/es/icons/AreaChartOutlined';
import SolutionOutlined from '@ant-design/icons/es/icons/SolutionOutlined';
import PieChartOutlined from '@ant-design/icons/es/icons/PieChartOutlined';
import FundProjectionScreenOutlined from '@ant-design/icons/es/icons/FundProjectionScreenOutlined';
import DotChartOutlined from '@ant-design/icons/es/icons/DotChartOutlined';
import PictureOutlined from '@ant-design/icons/es/icons/PictureOutlined';
import BarsOutlined from '@ant-design/icons/es/icons/BarsOutlined';
import SettingOutlined from '@ant-design/icons/es/icons/SettingOutlined'
  export default {
    SmileOutlined,
FundOutlined,
AreaChartOutlined,
SolutionOutlined,
PieChartOutlined,
FundProjectionScreenOutlined,
DotChartOutlined,
PictureOutlined,
BarsOutlined,
SettingOutlined
  }