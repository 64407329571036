import { useIntl } from 'umi';
import { GithubOutlined } from '@ant-design/icons';
import { DefaultFooter } from '@ant-design/pro-layout';

const Footer: React.FC = () => {
  const intl = useIntl();
  const defaultMessage = intl.formatMessage({
    id: 'app.copyright.produced',
    // defaultMessage: 'KAZENERGY',
  });

  const currentYear = new Date().getFullYear();

  return (
    <DefaultFooter
      copyright={`2003 - ${currentYear} ${defaultMessage}`}
      links={[
        {
          key: 'KAZENERGY',
          title: 'KAZENERGY',
          href: 'https://www.kazenergy.com/',
          blankTarget: true,
        },
        // {
        //   key: 'github',
        //   title: <GithubOutlined />,
        //   href: 'https://github.com/ant-design/ant-design-pro',
        //   blankTarget: true,
        // },
        // {
        //   key: 'Ant Design',
        //   title: 'Ant Design',
        //   href: 'https://ant.design',
        //   blankTarget: true,
        // },
      ]}
    />
  );
};

export default Footer;
